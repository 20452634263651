import React           from 'react';
import { Box, Flex }   from '@chakra-ui/react';
import { PiStepsFill } from 'react-icons/pi';

import Heading      from '@interness/web-core/src/components/text/Heading/Heading';
import MoreExpander from '@interness/web-core/src/components/structure/MoreExpander/MoreExpander';
import Link         from '@interness/web-core/src/components/elements/Link/Link';

const InfoBlocks = () => {
  return (
    <div>
      <Heading icon={<PiStepsFill/>} tag="h2">In 3 Schritten zu ihrem Einbauschrank</Heading>
      <Box>
        <Flex alignItems="center" backgroundColor="#f7f7f7" borderRadius={'xl'} boxShadow={'2xl'} p={['4', '10']}
              width="100%" flexDirection={['column', 'row', 'row']} mb={6}>
          <Flex alignItems="center" justifyContent="center" borderRadius="full" bg="brand.500" width="50px"
                height="50px" color="#fff" margin="30px">1</Flex>
          <Box textAlign="justify" width="90%" sx={{
            p: {
              marginBottom: '0'
            }
          }}>
            <h3>Kontaktaufnahme</h3>
            <MoreExpander title="Mehr...">
              <p>Haben Sie Interesse an einer Beratung oder möchten Sie einen Termin festlegen? Zögern Sie nicht, uns zu
                kontaktieren, um sicherzustellen, dass Ihr Anliegen nicht in der Hektik des täglichen Lebens
                untergeht.</p>
              <p>Falls Sie einen Besuch in einem unserer Showrooms wünschen oder einen Termin bei Ihnen zu Hause
                bevorzugen, stehen wir Ihnen gerne zur Verfügung. Kontaktieren Sie uns einfach per Telefon oder senden
                Sie uns eine Nachricht über unser <Link to="/kontakt">Kontaktformular</Link>.</p>
              <p>Wir sind auch stets bereit, Ihre Fragen zu beantworten, zum Beispiel ob die Anfertigung Ihres
                Wunschmöbelstücks möglich ist.</p>
            </MoreExpander>
          </Box>
        </Flex>
        <Flex alignItems="center" backgroundColor="#f7f7f7" borderRadius={'xl'} boxShadow={'2xl'} p={['4', '10']}
              width="100%" flexDirection={['column', 'row', 'row']} mb={6}>
          <Flex alignItems="center" justifyContent="center" borderRadius="full" bg="brand.500" width="50px"
                height="50px" color="#fff" margin="30px">2</Flex>
          <Box textAlign="justify" width="90%" sx={{
            p: {
              marginBottom: '0'
            }
          }}>
            <h3>Planung</h3>
            <MoreExpander title="Mehr...">
              <p>Nachdem wir gemeinsam einen Termin vereinbart haben, besucht Sie einer unserer erfahrenen
                Innenarchitekten, um den vorgesehenen Raum für Ihren Schrank genau auszumessen. Anschließend erstellen
                wir auf Wunsch ein detailliertes Projekt-Design inklusive eines unverbindlichen Kostenvoranschlags.</p>
              <p>Dank unserer 15-jährigen Erfahrung garantieren wir, dass wir die ideale Lösung für Ihren Schrank
                finden. Dabei achten wir darauf, keinen wertvollen Platz zu verschenken und gleichzeitig reichlich
                Stauraum für Ihre Bedürfnisse zu schaffen!</p>
            </MoreExpander>
          </Box>
        </Flex>
        <Flex alignItems="center" backgroundColor="#f7f7f7" borderRadius={'xl'} boxShadow={'2xl'} p={['4', '10']}
              width="100%" flexDirection={['column', 'row', 'row']}>
          <Flex alignItems="center" justifyContent="center" borderRadius="full" bg="brand.500" width="50px"
                height="50px" color="#fff" margin="30px">3</Flex>
          <Box textAlign="justify" width="90%" sx={{
            p: {
              marginBottom: '0'
            }
          }}>
            <h3>Realisierung</h3>
            <MoreExpander title="Mehr...">
              <p>Nachdem wir gemeinsam einen Termin vereinbart haben, besucht Sie einer unserer erfahrenen
                Innenarchitekten, um den vorgesehenen Raum für Ihren Schrank genau auszumessen. Anschließend erstellen
                wir auf Wunsch ein detailliertes Projekt-Design inklusive eines unverbindlichen Kostenvoranschlags.</p>
              <p>Dank unserer 15-jährigen Erfahrung garantieren wir, dass wir die ideale Lösung für Ihren Schrank
                finden. Dabei achten wir darauf, keinen wertvollen Platz zu verschenken und gleichzeitig reichlich
                Stauraum für Ihre Bedürfnisse zu schaffen!</p>
            </MoreExpander>
          </Box>
        </Flex>
      </Box>
    </div>
  )
}

export default InfoBlocks;